.alumnus-toast {
  border-radius: 10px !important;
  padding: 12px !important;
  max-height: none !important;

  .Toastify__progress-bar-theme--light {
    background: none;
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 70vw !important;
  }

  .Toastify__toast-container--bottom-left {
    bottom: 10px !important;
    transform: translateX(10px) !important;
  }
}
