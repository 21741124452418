.tooltip-container {
  padding-top: 10px;
  z-index: 1000 !important;
  inset: -10px auto auto 0px !important;
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;

  &::before {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }

  &::after {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow {
  left: 0;
  margin-top: -0.4rem;
  top: 10px;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::before {
  border-color: transparent transparent #c0c0c0 transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::after {
  border-color: transparent transparent #fff transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before {
  border-color: #c0c0c0 transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::after {
  border-color: #fff transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::before {
  border-color: transparent #c0c0c0 transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::after {
  border-color: transparent #fff transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::before {
  border-color: transparent transparent transparent #c0c0c0;
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::after {
  border-color: transparent transparent transparent #fff;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
