.phone_input {
  .PhoneInput {
    padding: 10px 12px;
    border-radius: 4px;
    border: 2px solid #ececec;
    color: #a7a7a7;

    input {
      border: none;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  &--small {
    .PhoneInput {
      padding: 8px 20px;

      @media (max-width: 576px) {
        border-radius: 8px;
        padding: 8px 10px;
      }
    }
  }

  &--error {
    border: 1px solid #ef2547 !important;
  }
}

.benefit-image {
  width: auto;
}

.shadow-box {
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.card-box {
  box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.10);
}

.program-box {
  box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.tab-program {
  border-radius: 8px;

  &:first-child {
    background: #000;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  button {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}


.bg-linear-bootcamp {
  background: linear-gradient(0deg, #111213 17.73%, rgba(46, 46, 46, 0.00) 30%);
}


.overlay-value {
  background: linear-gradient(0deg, rgba(16, 24, 32, 0.90) 0%, rgba(16, 24, 32, 0.90) 100%);
}