@import "src/styles/animate/animate";

.fade_in_left {
  @include fadeInLeft();
}

.opacity_parent {
  .opacity_child {
    opacity: 0;
  }

  &:hover {
    .opacity_child {
      opacity: 1;
    }
  }
}

.youtube_parent {
  .icon {
    top: calc(50% - 1em);
  }

  svg {
    font-size: 2em;
  }

  &:hover {
    .icon {
      top: calc(50% - 2em);
    }

    svg {
      font-size: 4em;
    }
  }

  @media (max-width: 576px) {
    .icon {
      top: calc(50% - 1.5em);
    }

    svg {
      font-size: 3em;
    }
  }
}

@keyframes toast_fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes toast_fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

.toast_animation {
  animation: toast_fadein 0.5s, toast_fadeout 0.5s 2.5s;
}

.popup_animation {
  transition-property: max-height;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.animate {
  animation: bannermove 20s linear infinite;
}

@keyframes bannermove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-left {
  animation: bannermoveleft 50s linear infinite;

  @media (max-width: 576px) {
    animation: bannermoveleft 50s linear infinite;
  }
}

@keyframes bannermoveleft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-right {
  animation: bannermoveright 50s linear infinite;

  @media (max-width: 576px) {
    animation: bannermoveright 30s linear infinite;
  }
}

@keyframes bannermoveright {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.color-change {
  animation: color-change 5s infinite;
}

@keyframes color-change {
  0% {
    color: #222325;
    background-clip: text;
    text-fill-color: transparent;
  }
  50% {
    // background: linear-gradient(255.69deg, #0dad8e 10.94%, #003bde 163.98%);
    // background-clip: text;
    // text-fill-color: transparent;
    color: #0dad8e;
  }

  100% {
    color: #222325;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.rotate-animation {
  transition: transform 0.2s ease-in;

  &__up {
    transform: rotate(-180deg);
  }

  &__down {
    transform: rotate(90deg);
  }
}
