.break-space {
  white-space: break-spaces;
}

.grid-col-auto {
  grid-template-columns: auto auto;
}

.text-base-responsive {
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}

.slider-row {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;
}

.slider-mobile {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;
  padding-right: 0;
  width: 100%;
  margin-bottom: 20px;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    background: transparent;
    width: 0;
    height: 0;
  }
}

.custom-slider {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;
  padding-right: 0;
  background-color: transparent;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    background: transparent;
    width: 0;
    height: 0;
  }
}

.markup {
  p {
    margin-bottom: 1rem;
  }

  li {
    list-style: disc;
  }

  @media (max-width: 576px) {
    li,
    p,
    span {
      font-size: 12px !important;
    }
  }
}

.markup_list {
  margin-bottom: 10px;
}

.overflow-auto {
  overflow: auto;
}

.underline-animation {
  /* Underline styles */
  button,
  a {
    display: block;
    position: relative;
    padding: 0.2em 0;
    color: #0038de;
    font-weight: 600;
  }

  /* Fade in */
  button::after,
  a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #0038de;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  button:hover::after,
  button:focus::after,
  a:hover::after,
  a:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  /* Slide in */
  button,
  a {
    overflow: hidden;
  }

  button::after,
  a::after {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }

  button:hover::after,
  button:focus::after,
  a:hover::after,
  a:focus::after {
    transform: translate3d(0, 0, 0);
  }
}
